import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { SettingsPersonalDetails } from '../../components/settings/SettingsPersonalDetails'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <>
      <SessionTimeoutModal accountType={AccountType.User} />
      <AuthRoute>
        <BasicLayout>
          <SettingsPersonalDetails />
        </BasicLayout>
      </AuthRoute>
    </>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Settings</title>
