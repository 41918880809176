import './SessionTimeoutModal.less'

import { Input, notification } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AccountType,
  setLastRequestTime as extendSessionTime,
  fetchApi,
  fetchCurrentUserData,
  getSessionTimeLeft,
  setLastLocationPath
} from '../../../requests'
import { routes } from '../../../routes'
import { DatacModal } from '../../common'

interface Props {
  blockTimeout?: boolean
  accountType: AccountType
}

export const SessionTimeoutModal: React.VFC<Props> = ({ blockTimeout, accountType }) => {
  const intlTimeoutModal = useScopedIntl('auth.session_timeout.modal')
  const LAST_MINUTES_IN_MODAL = 1
  const [showModal, setShowModal] = useState(false)
  const [minutesString, setMinutesString] = useState('')
  const [secondsString, setSecondsString] = useState('')

  useEffect(() => {
    let myInterval: ReturnType<typeof setTimeout>
    if (blockTimeout) {
      myInterval = setInterval(extendSessionTime, 1000)
    } else {
      myInterval = setInterval(intervalCallback, 1000)
    }
    return () => clearInterval(myInterval)
  }, [blockTimeout])

  const intervalCallback = () => {
    const { minutes, seconds } = getSessionTimeLeft()

    if (minutes >= LAST_MINUTES_IN_MODAL) {
      return
    }

    if (seconds < 0) {
      setLastLocationPath()
      notification.destroy()
      logout(true)
      return
    }

    setShowModal(true)

    setSecondsString(toStringWithLeadingZero(seconds))
    setMinutesString(toStringWithLeadingZero(minutes))
  }

  const toStringWithLeadingZero = (value: number) => (value < 10 ? `0${value}` : value.toString())

  const extendSession = () => {
    fetchCurrentUserData(
      { accountType },
      {
        onSuccess: () => setShowModal(false),
        onRequestError: () => logout(true)
      }
    )
  }

  const logout = (isSessionTimeout?: boolean) => {
    if (isSessionTimeout) {
      setLastLocationPath()
      navigate(routes.sessionTimeout(accountType))
      return
    }

    fetchApi.signOut({
      onSuccess: () => navigate(routes.signIn(accountType)),
      onRequestError: () => null
    })
  }

  return (
    <DatacModal
      title={intlTimeoutModal('title')}
      isOpened={showModal}
      onClose={() => logout()}
      onSubmit={() => extendSession()}
      className="session-timeout-modal"
      submitLabel={intlTimeoutModal('continue')}
      cancelLabel={intlTimeoutModal('logout')}
    >
      <p>{intlTimeoutModal('description.part1')}</p>
      <p>{intlTimeoutModal('description.part2')}</p>
      <div className="session-timeout-modal__timer-label">{intlTimeoutModal('timer.label')}</div>
      <div className="session-timeout-modal__timer">
        <Input size="large" value={minutesString[0]} disabled />
        <Input size="large" value={minutesString[1]} disabled />
        <div>:</div>
        <Input size="large" value={secondsString[0]} disabled />
        <Input size="large" value={secondsString[1]} disabled />
      </div>
      <p>{intlTimeoutModal('continue.label')}</p>
    </DatacModal>
  )
}
